import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import PageHero from "../components/Hero/PageHero";
import SEO from "../components/SEO/SEO";
import SliceZone from "../components/SliceZone/SliceZone";


export const query = graphql`
  query Page($uid: String) {
    allPrismicPage(
      filter: {
        uid: {
          eq: $uid
        }
      }
    ) {
      edges {
        node {
          data {
            page_title {
              text
            }
            meta_title {
              text
            }
            page_description {
              text
            }
            meta_description {
              text
            }
            body {
              ... on PrismicPageBodyText {
                primary {
                  section_title {
                    text
                  }
                  section_page_link_text {
                    text
                  }
                  section_page_link {
                    url
                    link_type
                  }
                  section_description {
                    text
                  }
                  text_color
                  background_color
                  icon_color
                }
                slice_type
              }
              ... on PrismicPageBodyProductGallery {
                items {
                  product_link {
                    url
                    link_type
                    document {
                      ... on PrismicProduct {
                        data {
                          body {
                            ... on PrismicProductBodyProductVariant {
                              items {
                                product_variant_title {
                                  text
                                }
                              }
                            }
                          }
                          product_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 1000
                                  quality: 80
                                  placeholder: TRACED_SVG
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                          product_title {
                            text
                          }
                        }
                      }
                    }
                  }
                  overlay_color
                }
                primary {
                  text_color
                }
                slice_type
              }
              ... on PrismicPageBodyForm {
                primary {
                  section_title {
                    text
                  }
                  section_description {
                    text
                  }
                  background_color
                  text_color
                }
                items {
                  form_type {
                    text
                  }
                  form_placeholder {
                    text
                  }
                  form_name {
                    text
                  }
                  form_label {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyBenefits {
                primary {
                  text_color
                  background_color
                }
                items {
                  benefit_item {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyCallToAction {
                primary {
                  text_color
                  background_color
                  action_text {
                    text
                  }
                  action_number {
                    text
                  }
                  action_link {
                    url
                    link_type
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyProcess {
                items {
                  process_stage_title {
                    text
                  }
                  process_stage_description {
                    text
                  }
                  process_stage_icon {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 120
                          quality: 100
                          placeholder: TRACED_SVG
                          formats: [PNG]
                        )
                      }
                    }
                  }
                }
                primary {
                  background_color
                  icon_color
                  section_title {
                    text
                  }
                  section_description {
                    text
                  }
                  text_color
                }
                slice_type
              }
              ... on PrismicPageBodyImageHeading {
                primary {
                  contact_email {
                    text
                  }
                  contact_hours {
                    text
                  }
                  contact_number {
                    text
                  }
                  overlay_color
                  text_color
                  section_title {
                    text
                  }
                  section_page_link {
                    url
                    link_type
                  }
                  section_page_link_text {
                    text
                  }
                  section_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1920
                          quality: 80
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                }
                items {
                  benefits_item {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageBodyPortfolioSlider {
                items {
                  portfolio_client_title {
                    text
                  }
                  portfolio_client_project_area {
                    text
                  }
                  portfolio_client_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1200
                          quality: 80
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                }
                primary {
                  text_color
                }
                slice_type
              }
              ... on PrismicPageBodyArticleList {
                primary {
                  background_color
                  text_color
                }
                items {
                  background_color
                  text_color
                  articles_to_link {
                    url
                    link_type
                    document {
                      ... on PrismicArticle {
                        data {
                          article_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 600
                                  quality: 80
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                          article_post_date(
                            formatString: "MMMM DD, YYYY"
                          )
                          article_title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;


const PageTemplate = ({
  data
}) => {
  if (!data) return null;

  const document = data.allPrismicPage.edges[0].node.data;

  const pageMeta = {
    metaTitle: document.meta_title.text,
    metaDescription: document.meta_description.text,
  };

  const pageContent = {
    pageTitle: document.page_title.text,
    pageDescription: document.page_description.text,
  };

  return (
    <Layout
      isLandingPage={ false }
      headerTextColor="#231F20"
    >
      <SEO
        title={ pageMeta.metaTitle }
        description={ pageMeta.metaDescription }
      />

      <PageHero
        pageTitle={ pageContent.pageTitle }
        pageDescription={ pageContent.pageDescription }
      />

      <SliceZone sliceZone={ document.body } />
    </Layout>
  )
}

export default PageTemplate;
