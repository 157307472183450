import * as React from "react";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  color: #231F20;
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  margin-top: calc(2 * ${ PADDINGS.header.mobile.y } + ${ SVGS.brandLogo.height });
  padding-bottom: 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin-top: calc(2 * ${ PADDINGS.header.tablet.y } + ${ SVGS.brandLogo.height });
    padding-bottom: 4rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin-top: calc(2 * ${ PADDINGS.header.laptop.y } + ${ SVGS.brandLogo.height });
    padding-bottom: 5rem;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin-top: calc(2 * ${ PADDINGS.header.desktop.y } + ${ SVGS.brandLogo.height });
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin-top: calc(2 * ${ PADDINGS.header.largeDesktop.y } + ${ SVGS.brandLogo.height });
  }
`;

const PageDescription = styled.p`
  margin: 0;
  margin-bottom: 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    margin-bottom: 3rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: 90%;
  }
`;

const PageTitle = styled.h1`
  margin: 0;
`;


const PageHero = ({
  pageTitle,
  pageDescription
}) => {
  return (
    <Section>
      <Container>
        <PageDescription>
          { pageDescription }
        </PageDescription>
        <PageTitle>
          { pageTitle }
        </PageTitle>
      </Container>
    </Section>
  )
}


export default PageHero;
